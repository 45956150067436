import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.loja, campo: 'abreviacao' },
  { rotulo: bibDialogo.data, campo: 'movimentacaoData' },
  { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, campo: 'movimentacaoNumeroSerie', campoOrdenacao: 'movimentacaoNumero' },
  { rotulo: bibDialogo.clienteFornecedor, campo: 'parceiro' },
  { rotulo: bibDialogo.responsavel, campo: 'colaboradorNome' },
  { rotulo: bibDialogo.produto, campo: 'produto' },
  { rotulo: bibDialogo.grupo, campo: 'grupo' },
  { rotulo: bibDialogo.operacao, campo: 'operacao' },
  { rotulo: bibDialogo.naturezaOperacao, campo: 'naturezaOperacao' },
  { rotulo: bibDialogo.negociacao, campo: 'negociacao' },
  { rotulo: bibDialogo.cfop, campo: 'cfop' },
  { rotulo: bibDialogo.ncm, campo: 'ncm' },
];

export default grupos;
