import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import filtros from './acessorio/filtro';
import { RelatorioComponent } from '../relatorio.component';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import totais from './acessorio/total';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';

@Component({
  templateUrl: './r2012.component.html',
})
export class R2012Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public recebimentos: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public rotuloTipoTitulo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30).apelido;
  public mostrarInformacoesRecebimento: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'S', 'N'));
      this.recebimentos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RecebimentoCartao').lista;
      this.ordenarAgrupar(this.recebimentos);
      this.ehImprimirRelatorio();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.mostrarInformacoesRecebimento = this.definirRegra(relatorioAdicional, 273, this.mostrarInformacoesRecebimento);
    this.posicionarTotais();
  }

  posicionarTotais(): void {
    this.posicionarTotalizador('valorParcela', this.mostrarInformacoesRecebimento, 1);
    this.posicionarTotalizador('valorLiquido', this.mostrarInformacoesRecebimento, 1);
    this.posicionarTotalizador('valorAberto', this.mostrarInformacoesRecebimento, 0);
  }
}
