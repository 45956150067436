import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroFinanceiro: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.nao, id: "'N'" },
  { nome: bibDialogo.sim, id: "'R', 'D'" },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.produto, nome: 'ID_PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.negociacao, nome: 'ID_NEGOCIACAO', coluna: 6, servicoWeb: bibServico.negociacao, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.ncm, nome: 'NCM', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.clienteFornecedor, nome: 'ID_PARCEIRO', coluna: 6, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.operacao, nome: 'IDS_OPERACAO', coluna: 6, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.naturezaOperacao, nome: 'IDS_NATUREZA_OPERACAO', coluna: 6, servicoWeb: bibServico.naturezaOperacao, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.gerouFinanceiro, nome: 'FINANCEIRO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.lista, valor: '-', lista: filtroFinanceiro },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.safra, nome: 'ID_SAFRA', coluna: 6, servicoWeb: bibServico.safra, tipo: bibPropriedade.filtro.busca },
]);
export default filtros;
