<layout (filtroEmt)="listar($event, 0,0)" [filtros]="filtros" [subTitulo]="comissoes.length > 0 ? bibDialogo.colaborador.toUpperCase() + ': ' + comissoes[0].colaborador : null">
  <registro [posicao]="utilSessao.posicao" [paginacao]="utilSessao.numeroRegistro" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th *ngIf="apresentaDadosComissao" class="l-5 icone naoImprimir"></th>
        <th *ngIf="apresentaDadosComissao" class="l-5 naoImprimir"></th>
        <!-- <th *ngIf="apresentaDadosComissao" class="l-10 clique fonte-menor" (click)="listar(add('mesAnoReferencia'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.ref }}<ajuda [ajuda]="bibDialogo.referencia + ' (' + bibDialogo.mesAno.toLowerCase() + ')'"></ajuda></th> -->
        <th class="l-5 clique fonte-menor" (click)="listar(add('lojaAbreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-5 text-right naoImprimir clique fonte-menor" (click)="listar(add('numeroMovimentacao'), this.utilSessao.posicao, this.paginacao)">{{ 'Nº' }}<ajuda [ajuda]="bibDialogo.numero + ' ' + bibDialogo.movimentacao.toLowerCase()"></ajuda></th>
        <th class="l-10 clique fonte-menor" (click)="listar(add('dataMovimentacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}<ajuda [ajuda]="bibDialogo.data + ' ' + bibDialogo.movimentacao.toLowerCase()"></ajuda></th>
        <th class="l-15 clique fonte-menor" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cliente }}</th>
        <th class="l-10 text-right clique fonte-menor" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorComissao }}</th>
        <th class="l-10 text-right clique fonte-menor" (click)="listar(add('valorLiberado'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorLiberado }}<ajuda [ajuda]="bibDialogo.ajudaComissaoLiberada"></ajuda></th>
        <th class="l-10 text-right fonte-menor">{{ bibDialogo.valorNaoLiberado }}<ajuda [ajuda]="bibDialogo.ajudaComissaoNaoLiberada"></ajuda></th>
        <th class="l-10 text-right clique fonte-menor" (click)="listar(add('valorPago'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorPago }}<ajuda [ajuda]="bibDialogo.ajudaComissaoPaga"></ajuda></th>
        <th class="l-10 text-right naoImprimir clique fonte-menor" (click)="listar(add('valorDisponivel'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorAguardando }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.ajudaComissaoAguardando"></ajuda></th>
        <th class="l-10 text-right clique fonte-menor" (click)="listar(add('valorRestante'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorTotalAPagar }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.ajudaComissaoValorTotalAPagar"></ajuda></th>
      </tr>
    </thead>
    <tbody>
      <tr class="naoImprimir" *ngIf="comissoes.length > 0 && apresentaDadosComissao">
        <td></td>
        <td>
          <check (alteracao)="selecionarComissaoDisponivel($event)"></check>
        </td>
        <td class="pl-1 textoNormal" colspan="5">
          {{ bibDialogo.selecioneComissaoAguardando }}
        </td>
      </tr>
      <ng-container *ngFor="let comissao of comissoes; let i = index">
        <tr>
          <td *ngIf="apresentaDadosComissao" class="l-5 naoImprimir">
            <icone *ngIf="comissao.valorDisponivel == 0 && comissao.selecionado == 'S'" [icone]="bibIcone.circulo" [cor]="bibPropriedade.icone.vermelho" [mensagem]="bibDialogo.semValorDisponivel"></icone>
          </td>
          <td *ngIf="apresentaDadosComissao" class="l-5 naoImprimir">
            <check *ngIf="comissao.valor != comissao.valorPago" [campo]="comissao.selecionado" [foco]="i == 0" (alteracao)="setSelecionado(comissao, $event)"></check>
          </td>
          <!-- <td *ngIf="apresentaDadosComissao">{{ comissao.mesAnoReferencia }}</td> -->
          <td>{{ comissao.lojaAbreviacao }}</td>
          <td class="text-right"><a href="#" (click)="visualizar(comissao.idMovimentacao)">{{ comissao.numeroMovimentacao }}</a></td>
          <td>{{ comissao.dataMovimentacao | data }}</td>
          <td class="limitar">{{ comissao.parceiro }}</td>
          <td class="text-right">{{ comissao.valor | monetario }}</td>
          <td class="text-right">{{ comissao.valorLiberado | monetario }}</td>
          <td class="text-right">{{ (comissao.valor - comissao.valorLiberado) | monetario }}</td>
          <td class="text-right">{{ comissao.valorPago | monetario }}</td>
          <td class="text-right naoImprimir">{{ comissao.valorDisponivel | monetario }}</td>
          <td class="text-right" [ngClass]="comissao.valorRestante == 0 ? 'tachado' : ''">{{ comissao.valorRestante | monetario }}</td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="comissoes.length > 0">
      <tr *ngFor="let totalComissao of totalComissoes">
        <td colspan="6"></td>
        <td class="text-right font-weight-bold">{{ totalComissao.valorTotalComissao | monetario }}</td>
        <td class="text-right font-weight-bold">{{ totalComissao.valorTotalLiberado | monetario }}</td>
        <td class="text-right font-weight-bold">{{ totalComissao.valorTotalNaoLiberado | monetario }}</td>
        <td class="text-right font-weight-bold">{{ totalComissao.valorTotalPago | monetario }}</td>
        <td class="text-right font-weight-bold">{{ totalComissao.valorTotalAguardando | monetario }}</td>
        <td class="text-right font-weight-bold">{{ totalComissao.valorTotalAPagar | monetario }}</td>                
        <td></td>
      </tr>
    </tfoot>
  </table>
  <totalizador [valorTotalizador]="valorTotalPagar | monetario"></totalizador>
</layout>
<div class="row ml-2 alinhar-vertical-direita">
  <data *ngIf="apresentaDadosComissao" [rotulo]="bibDialogo.dataVencimento" [campo]="dataVencimento" (alteracao)="dataVencimento = $event" [obrigatorio]="true" [calendario]="false" [compacto]="compacto"></data>
  <botao class="ml-2 alinhar-vertical" *ngIf="apresentaDadosComissao" [compacto]="compacto" [legenda]="bibDialogo.solicitarPagamento" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="gerarComissaoLote()" [desabilitado]="!temPermissaoEdicao"></botao>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
