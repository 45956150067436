<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)">
  <ng-container *ngFor="let movimentacao of apresentaMovimentacoes; let i = index">
    <table class="fonte" [ngClass]="[ehMatricial ? 'matricial' : '', ehModoClaro ? '' : 'tabela-modo-escuro']">
      <tbody>
        <tr style="border-top: none;">
          <div class="row" [ngClass]="[ehMatricial ? 'matricial' : '', ehModoClaro ? '' : 'tabela-modo-escuro']">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <div class="row">
                <img [src]="foto ? foto : imagemEmpresa" [alt]="empresa.nome" height="80" width="80" />
              </div>
            </div>
            <div class="p-0 mt-3 ml-3">
              <h1 class="relatorio-rotulo">{{ empresa ? empresa.nome : '' }}</h1>
              <h2 class="relatorio-rotulo">{{ movimentacao.operacao + ' ' + (mostraVia == true && movimentacao.via != null ? movimentacao.via : '') }}</h2>
              <div *ngIf="movimentacao.status != 'S'" class="tarja-nao-finalizado">{{ bibDialogo.naoFinalizado }}</div>
              <div class="row">
                <span class="relatorio-rotulo text-right"> {{ bibDialogo.numero + ':' }} </span> {{ movimentacao.numero }}
              </div>
              <div class="row">
                <span class="relatorio-rotulo text-right">{{ bibDialogo.data + ':' }}</span>
                <span class="r-3"> {{ movimentacao.data | data }} </span>
                <span class="relatorio-rotulo text-right">{{ movimentacao.identificacao == 3 || movimentacao.identificacao == 4 ? ((movimentacao.identificacao == 3 || movimentacao.identificacao == 4) && movimentacao.entradaSaidaInterna == -1 ? bibDialogo.dataSaida + ': ' : bibDialogo.dataEntrada + ': ') : '' }} </span>
                <span class="r-2"> {{ movimentacao.identificacao == 3 || movimentacao.identificacao == 4 ? (movimentacao.dataSaida | data) : '' }} </span>
                <span class="relatorio-rotulo text-right">{{ bibDialogo.dataHoraImpressao + ':' }} </span>
                <span class="r-2"> {{ dataAtual | data: 'dd/MM/yyyy HH:mm:ss' }} </span>
              </div>
            </div>
          </div>
        </tr>
        <tr>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.loja + ': ' }} </span>{{ movimentacao.loja }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-uppercase r-2 text-right">{{ bibDialogo.email + ': ' }} </span> <span class="r-8 text-lowercase"> {{ movimentacao.lojaEmail }} </span> <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.telefone + ':' }} </span> <span>{{ movimentacao.lojaTelefone }} </span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.endereco + ': ' }} </span>{{ movimentacao.lojaEndereco }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.cnpj + ': ' }} </span> <span class="r-3">{{ movimentacao.lojaCnpj }} </span> <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.inscricao + ':' }} </span> <span class="r-3">{{ movimentacao.lojaInscricao }} </span>
          </div>
          <div class="row">
            <span *ngIf="movimentacao.entradaSaidaInterna == 1" class="relatorio-rotulo r-2 text-right">{{ bibDialogo.comprador + ':' }} </span>
            <span *ngIf="movimentacao.entradaSaidaInterna == -1" class="relatorio-rotulo r-2 text-right">{{ bibDialogo.vendedor + ':' }} </span>
            <span *ngIf="movimentacao.entradaSaidaInterna == 0" class="relatorio-rotulo r-2 text-right">{{ bibDialogo.responsavel + ':' }} </span>
            <span class="r-9">{{ movimentacao.colaborador }} </span>
          </div>
        </tr>
        <tr>
          <div class="row">
            <span *ngIf="movimentacao.entradaSaidaInterna == 1" class="relatorio-rotulo r-2 text-right">{{ bibDialogo.fornecedor + ':' }} </span>
            <span *ngIf="movimentacao.entradaSaidaInterna == -1" class="relatorio-rotulo r-2 text-right">{{ bibDialogo.cliente + ':' }} </span>
            <span *ngIf="movimentacao.entradaSaidaInterna == 0" class="relatorio-rotulo r-2 text-right">{{ bibDialogo.responsavel + ':' }} </span> {{ movimentacao.parceiro }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.email + ':' }} </span> <span class="r-8 text-lowercase"> {{ movimentacao.parceiroEmail }} </span> <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.telefone + ':' }} </span> <span class="r-2"> {{ movimentacao.parceiroTelefone }} </span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.endereco + ':' }} </span>{{ movimentacao.parceiroEndereco }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.cpf + ':' }} </span> <span class="r-3"> {{ movimentacao.parceiroCnpjCpf }} </span> <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.inscricao + ':' }} </span> <span class="r-4"> {{ movimentacao.parceiroInscricaoEstadual }} </span>
          </div>
        </tr>

            <div class="tabela-complementar">
              <thead>
                <tr>
                  <th class="r-0-5 text-right">{{ bibDialogo.numeroAbreviacao }}</th>
                  <th class="r-1 text-right">{{ bibDialogo.qtd }}</th>
                  <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
                  <th class="r">{{ bibDialogo.descricaoPodutos }}</th>
                  <th *ngIf="usaValorUnitario || usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.valor }}<br />{{ bibDialogo.unitario.toLowerCase() }}</th>
                  <th *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.desconto }}</th>                  
                  <th *ngIf="usaValorUnitario && !usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.total }}</th>
                  <th *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.total }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let movimentacaoProduto of movimentacaoProdutos | filtrarMovimentacaoVinculada: { idMovimentacao: movimentacao.id }; let j = index">
                  <ng-container>
                    <td class="r-0-5 text-right">{{ movimentacaoProduto.produtoNumero }}</td>
                    <td class="r-1 text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
                    <td class="r-0-5 fonte-endereco limitar">{{ movimentacaoProduto.unidadeAbreviacao }}</td>
                    <td class="r limitar fonte-endereco">{{ usaReferencia && movimentacaoProduto.referencia != undefined ? movimentacaoProduto.referencia + ' - ' + movimentacaoProduto.produto : movimentacaoProduto.produto  }}</td>
                    <td *ngIf="usaValorUnitario || usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.valorUnitario + movimentacaoProduto.descontoValorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
                    <td *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.descontoValor + (movimentacaoProduto.quantidade * movimentacaoProduto.descontoValorUnitario) + movimentacaoProduto.descontoRateado | monetario: this.empresaRegraCasasDecimais }}</td>                    
                    <td *ngIf="usaValorUnitario && !usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.quantidade * (movimentacaoProduto.valorUnitario + movimentacaoProduto.descontoValorUnitario) | monetario }}</td>
                    <td *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>                    
                  </ng-container>
                </tr>
              </tbody>
            </div>
            <div>
              <td *ngIf="usaValorUnitario || usaDescontoValorFinal" [colSpan]="movimentacao.descontoTotal ? 7 : 6"></td>
              <td *ngIf="usaValorUnitario || usaDescontoValorFinal" class="r relatorio-negrito border-top border-dark text-right">{{ movimentacao.valorTotalProduto + (usaDescontoValorFinal ? 0 : movimentacao.descontoValorUnitarioTotal) | monetario }}</td>
            </div>
     
        <tr>
          <div *ngIf="movimentacao.descontoTotal > 0 && usaValorUnitario && !usaDescontoValorFinal" class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.desconto + ':' }} </span> {{ movimentacao.descontoTotal | monetario }}
          </div>
          <div *ngIf="movimentacao.adicionais > 0 && usaValorUnitario" class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.adicionais + ':' }} </span> {{ movimentacao.adicionais | monetario }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.total + ':' }}</span> <span class="relatorio-negrito"> {{ movimentacao.valorTotal | monetario }} &nbsp;</span> {{ this.util.escreverValor(movimentacao.valorTotal) }}
          </div>
        </tr>
        <tr *ngIf="usaFormaPagamento">
          <span class="r relatorio-rotulo">{{ bibDialogo.formaPagamento + ': '}}</span>{{ movimentacao.negociacao }}   
          <div class="row r-12">
            <ng-container *ngFor="let movimentacaoParcela of movimentacaoParcelas | filtrarMovimentacaoVinculada: { idMovimentacao: movimentacao.id }; let i = index">
              <div class="r-4 relatorio-negrito text-right">{{ i + 1 + 'º ' + (movimentacaoParcela.valorParcela | monetario) + ' em ' + (movimentacaoParcela.dataVencimento | data) + (movimentacaoParcela.tipoTitulo ? ' ' + movimentacaoParcela.tipoTitulo : '') }}</div>
            </ng-container>
          </div>
        </tr>
        <tr *ngIf="usaObservacao && movimentacao.observacao">
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.observacao + ':' }} </span>{{ movimentacao.observacao }}
          </div>
        </tr>
        <tr *ngIf="empresa.contrato && movimentacao.entradaSaidaInterna == -1 && movimentacao.identificacao != 4">
          <div class="r relatorio-rotulo">{{ bibDialogo.contrato }}</div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.clausulas + ':' }} </span>{{ empresa.contrato }}
          </div>
        </tr>
        <tr>
          <div class="row justify-content-center">
            <div class="r-6 assinatura text-center">{{ movimentacao.parceiro }}</div>
          </div>
        </tr>
      </tbody>
      <div *ngIf="i + 1 < movimentacoes.length" class="separador naoImprimir" [ngClass]="ehModoClaro ? '' : 'separador-modo-escuro'"></div>
    </table>
  </ng-container>
</relatorioLayout>
