import { Component } from '@angular/core';
import { PesquisaChequeComponent } from 'src/app/formulario/pesquisa/pesquisaCheque/pesquisaCheque.component';
import { Cheque } from 'src/app/modelo/cheque';
import { Conciliacao } from 'src/app/modelo/conciliacao';
import { Conta } from 'src/app/modelo/conta';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { Transferencia } from 'src/app/modelo/transferencia';
import { TransferenciaCheque } from 'src/app/modelo/transferenciaCheque';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './transferenciaBancariaFrm.component.html',
})
export class TransferenciaBancariaFrmComponent extends PaginaComponent {
  public bloqueado: boolean = false;
  public componente: any;
  public conciliacoes: Conciliacao[] = [];
  public contaDestino: Conta = new Conta();
  public contas: Conta[] = [];
  public origemContas: Conta[] = [];
  public transferencia: Transferencia = new Transferencia();
  public marcado: boolean = false;

  public especies: any[] = [
    { id: 1, nome: this.bibDialogo.dinheiro.toUpperCase() },
    { id: 2, nome: this.bibDialogo.cartao.toUpperCase() },
    { id: 4, nome: this.bibDialogo.cheque.toUpperCase() },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarConta();
    this.bloqueado = this.util.coalesce(this.utilSessao.getIdentificacao('bloqueado')?.conteudo, false);
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.cheque) {
        const cheques: Cheque[] = this.plainToClass(Cheque, identificacao.conteudo);
        this.inserirTransferenciaCheque(cheques);
      }
    });
  }

  ehAlteracao(): void {
    this.transferencia = this.plainToClass(Transferencia, this.ehAtualizacao(this.transferencia));
    if (this.transferencia.id) {
      this.listarTransferencia(this.transferencia.id);
    }
  }

  listarTransferencia(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.transferencia).subscribe((res) => {
      this.transferencia = this.plainToClass(Transferencia, res[0]) as any;
      this.bloqueado = this.transferencia.especie == 2 ? true : this.bloqueado;
      this.comunicacaoService.listar(new Transporte([new Criterio('ID_TRANSFERENCIA', id), new Criterio('BUSCAR_CONCILIACAO_ORIGEM', 'S')]), this.bibServico.conciliacao).subscribe((res) => {
        this.conciliacoes = this.plainToClass(Conciliacao, res) as any;
        this.transferencia.tipoTituloTipo = this.transferencia.especie;
        if (this.transferencia.tipoTituloTipo == 4) {
          this.listarTransferenciaCheque();
        }
      });
    });
  }

  listarTransferenciaCheque(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_TRANSFERENCIA', this.transferencia.id)), this.bibServico.transferenciaCheque).subscribe((res) => {
      this.transferencia.transferenciaCheques = this.plainToClass(TransferenciaCheque, res) as any;
    });
  }

  listarConta(): void {
    const usuario: Usuario = this.utilSessao.getUsuario();
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('CONTA_BANCARIA_E_CAIXA', 'S'));
    criterios.push(new Criterio('ID_USUARIO_LOJA', usuario.id));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.conta).subscribe((res) => {
      this.contas = this.plainToClass(Conta, res) as any;
      if (this.transferencia.idLoja) {
        this.filtrarContasDeLojaOrigem();
      }
    });
  }

  filtrarContasDeLojaOrigem(): void {
    const idContaUsuarioAcessos: number[] = this.utilSessao.getUsuarioContas().map((usuarioConta) => usuarioConta.idConta);
    const contas: Conta[] = this.contas.filter((conta) => conta.idLoja == this.transferencia.idLoja);
    this.origemContas = contas.filter((conta) => idContaUsuarioAcessos.includes(conta.id));
  }

  inserirTransferenciaCheque(cheques: Cheque[]): void {
    cheques.forEach((cheque) => {
      const transferenciaCheque = this.transferencia.transferenciaCheques.find((transferenciaCheque) => transferenciaCheque.idCheque == cheque.id);
      if (transferenciaCheque == undefined) {
        const transferenciaCheque: TransferenciaCheque = new TransferenciaCheque();
        transferenciaCheque.chequeNumero = cheque.numero;
        transferenciaCheque.chequeData = cheque.data;
        transferenciaCheque.chequeDataPreDatada = cheque.dataPreDatada;
        transferenciaCheque.chequeParceiro = cheque.parceiro;
        transferenciaCheque.chequeValor = cheque.valor;
        transferenciaCheque.chequeIdLoja = cheque.idLoja;
        transferenciaCheque.chequeAbreviacao = cheque.abreviacao;
        transferenciaCheque.idCheque = cheque.id;
        this.transferencia.transferenciaCheques.push(transferenciaCheque);
        this.transferencia.valor += cheque.valor;
      } else {
        if (transferenciaCheque.excluido) {
          transferenciaCheque.excluido = false;
          this.transferencia.valor += cheque.valor;
        }
      }
    });
  }

  buscarConciliacaoCartao(selecionaTodos: boolean = true): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('CONCILIADO', 'S'));
    criterios.push(new Criterio('ID_LOJA', this.transferencia.idLoja));
    criterios.push(new Criterio('ID_CONTA', this.transferencia.idContaOrigem));
    criterios.push(new Criterio('TRANSFERENCIA_DISPONIVEL_ESPECIE_CARTAO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.conciliacao).subscribe((res) => {
      this.conciliacoes = this.plainToClass(Conciliacao, res) as any;
      if (this.conciliacoes.length > 0) {
        const valorTotal: number = this.conciliacoes.reduce((valor, conciliacao) => valor + conciliacao.valor * (conciliacao.tipo == 'R' ? 1 : -1), 0);
        const valorLiquidoTotal: number = this.conciliacoes.reduce((valor, conciliacao) => valor + conciliacao.valorLiquido * (conciliacao.tipo == 'R' ? 1 : -1), 0);
        this.transferencia.valor = valorTotal;
        this.gerarConciliacoes(this.conciliacoes, valorTotal * -1, valorLiquidoTotal * -1,);
        if (selecionaTodos) {
          this.selecionarTodos('S');
        }
        this.marcado = true;
      }
    });
  }

  gerarConciliacoes(conciliacoes: Conciliacao[], valorTotal: number, valorLiquidoTotal: number): void {
    const chave: string = 'T' + new Date().getTime().toString() + '_' + this.utilSessao.getUsuario().id;
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_ESPECIE', 12), new Criterio('ATIVO', 'S')]), this.bibServico.tipoTitulo).subscribe((res) => {
      const tipoTitulo: TipoTitulo = this.plainToClass(TipoTitulo, res[0]) as any;
      this.transferencia.conciliacoes = conciliacoes.slice();
      conciliacoes.forEach((conciliacao) => {
        this.transferencia.conciliacoes.push(this.gerarConciliacao(conciliacao, chave, tipoTitulo));
      });
      this.transferencia.conciliacoes.push(this.gerarConciliacao(conciliacoes[0], chave, tipoTitulo, valorTotal, valorLiquidoTotal));
    });
  }

  gerarConciliacao(conciliacao: Conciliacao, chave: string, tipoTitulo: TipoTitulo, valorTotal: number = null, valorLiquidoTotal: number = null): Conciliacao {
    const chaveOrigem: string = 'T' + chave;
    let novaConciliacao: Conciliacao = new Conciliacao();
    novaConciliacao.origem = 1;
    novaConciliacao.chave = valorTotal ? chaveOrigem : chave;
    novaConciliacao.conciliado = valorTotal ? 'S' : this.contaDestino.conciliacaoAutomatica == 'S' ? 'S' : 'N';
    novaConciliacao.tipo = valorTotal ? (valorTotal > 0 ? 'R' : 'D') : conciliacao.tipo;
    novaConciliacao.idLoja = conciliacao.idLoja;
    novaConciliacao.idConta = valorTotal ? conciliacao.idConta : this.transferencia.idContaDestino;
    novaConciliacao.observacao = conciliacao.observacao;
    novaConciliacao.dataPrevista = conciliacao.dataPrevista;
    novaConciliacao.idTipoTitulo = tipoTitulo && tipoTitulo.id ? tipoTitulo.id : conciliacao.idTipoTitulo;
    novaConciliacao.nomeParceiro = valorTotal ? 'TRANSFERÊNCIA' : conciliacao.nomeParceiro;
    novaConciliacao.idParceiro = valorTotal ? null : conciliacao.idParceiro;
    novaConciliacao.conta = valorTotal ? conciliacao.conta : this.transferencia.contaDestino;
    novaConciliacao.idConciliacaoVinculada = valorTotal ? null : conciliacao.id;
    novaConciliacao.numeroDocumento = valorTotal ? null : conciliacao.numeroDocumento;
    novaConciliacao.dataConciliacao = valorTotal ? new Date() : null;
    novaConciliacao.idEmpresa = this.utilSessao.getUsuario().idEmpresa;
    novaConciliacao.valorTotal = valorTotal ? (valorTotal > 0 ? valorTotal : valorTotal * -1) : conciliacao.valorTotal;
    novaConciliacao.valor = valorTotal ? (valorTotal > 0 ? valorTotal : valorTotal * -1) : conciliacao.valor;
    novaConciliacao.valorLiquido = valorLiquidoTotal ? (valorLiquidoTotal > 0 ? valorLiquidoTotal : valorLiquidoTotal * -1) : conciliacao.valorLiquido;
    return novaConciliacao;
  }

  excluirTransferenciaCheque(transferenciaCheque: TransferenciaCheque): void {
    transferenciaCheque.excluido = true;
    this.transferencia.valor -= transferenciaCheque.chequeValor;
  }



  persistirTransferencia(novo: boolean = false): void {
    this.filtrarConciliacoesSelecionadas();
    if (this.ehValido()) {
      const transferenciaChequesExcluidos: TransferenciaCheque[] = this.transferencia.transferenciaCheques.filter((transferenciaCheque) => (transferenciaCheque.excluido == true && transferenciaCheque.id) || (this.transferencia.tipoTituloTipo != 4 && transferenciaCheque.id));
      this.transferencia.transferenciaCheques = this.transferencia.tipoTituloTipo == 4 ? this.transferencia.transferenciaCheques.filter((transferenciaCheque) => transferenciaCheque.excluido == false) : [];
      const transporte: Transporte = new Transporte(this.transferencia);
      transporte.adicionar(transferenciaChequesExcluidos.map((transferenciaChequesExcluido) => transferenciaChequesExcluido.id));
      super.persistir(transporte, this.bibServico.transferencia, novo ? new Transferencia() : null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.bloqueado = true;
          this.definirId([this.transferencia], this.bibClasse.transferencia, true);
          this.definirId(this.transferencia.transferenciaCheques, this.bibClasse.transferenciaCheque);
        } else {
          this.limparConciliacaoCartao();
          this.buscarConciliacaoCartao(false);
          transferenciaChequesExcluidos.forEach((transferenciaChequeExcluido) => this.transferencia.transferenciaCheques.push(transferenciaChequeExcluido));
        }
      });
    }
  }

  filtrarConciliacoesSelecionadas(): void {
    if (this.transferencia.tipoTituloTipo == 2) {
      this.transferencia.valor = 0;
      const conciliacoesSelecionadas: Conciliacao[] = [];
      for (let conciliacao of this.transferencia.conciliacoes) {
        if (conciliacao.selecionada == 'S') {
          this.transferencia.valor += conciliacao.valor;
          conciliacoesSelecionadas.push(conciliacao);
        }
      }
      this.transferencia.conciliacoes = conciliacoesSelecionadas;

    }
  }

  limparConciliacaoCartao(): void {
    this.transferencia.conciliacoes.forEach((conciliacao) => {
      conciliacao.selecionada = 'N';
    })
    this.transferencia.conciliacoes = [];
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoContasIguais: boolean = this.ehValidoContasIguais();
    const ehValidoValor: boolean = this.ehValidoValor();
    return ehValidoObrigatorio && ehValidoContasIguais && ehValidoValor;
  }

  ehValidoObrigatorio(): boolean {
    if (this.transferencia.data && this.transferencia.idContaOrigem && this.transferencia.idContaDestino) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoContasIguais(): boolean {
    if (this.transferencia.idContaOrigem == this.transferencia.idContaDestino) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.contasIguais));
      return false;
    }
    return true;
  }

  ehValidoValor(): boolean {
    if (this.transferencia.valor <= 0 || this.transferencia.valor == null) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.valorTransferirIncorreto));
      return false;
    }
    return true;
  }

  setIdContaOrigem(transferencia: Transferencia, conta: Conta): void {
    transferencia.idContaOrigem = conta.id;
    transferencia.contaOrigem = conta.nome;
  }

  setContaDestino(transferencia: Transferencia, conta: Conta): void {
    transferencia.idContaDestino = conta.id;
    transferencia.contaDestino = conta.nome;
    this.contaDestino = conta;
  }

  setIdLoja(transferencia: Transferencia, id: number): void {
    transferencia.idLoja = id;
    this.listarConta();
  }

  setIdEspecie(transferencia: Transferencia, idEspecie: number): void {
    transferencia.especie = idEspecie;
    transferencia.tipoTituloTipo = idEspecie;
    if (transferencia.tipoTituloTipo == 2 || transferencia.tipoTituloTipo == 4) {
      transferencia.valor = 0;
    }
  }

  setTipoTitulo(transferencia: Transferencia, tipoTitulo: TipoTitulo): void {
    transferencia.idTipoTitulo = tipoTitulo.id;
    transferencia.tipoTituloTipo = tipoTitulo.idEspecie;
    if (transferencia.tipoTituloTipo == 4) {
      transferencia.valor = 0;
    }
  }

  setConciliacaoSelecionada(conciliacao: Conciliacao, marcada: string) {
    conciliacao.selecionada = marcada;
  }

  selecionarTodos(marcado: string): void {
    this.conciliacoes.forEach((conciliacao) => {
      conciliacao.selecionada = marcado;
    })
  }

  ehLoja(): void {
    if (this.transferencia.idLoja == null) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecioneLoja));
    }
  }

  abrirModalCheque(): void {
    this.utilSessao.setIdentificacao(new Identificacao('idsConta', [this.transferencia.idContaOrigem]));
    this.utilSessao.setIdentificacao(new Identificacao('conta', this.transferencia.contaOrigem));
    this.utilSessao.setIdentificacao(new Identificacao('chequesSelecionados', this.montarCheque()));
    this.utilSessao.setIdentificacao(new Identificacao('chequeOrigemBaixa', true));
    this.componente = PesquisaChequeComponent;
  }

  montarCheque(): Cheque[] {
    const chequesSelecionados: Cheque[] = [];
    this.transferencia.transferenciaCheques.forEach((transferenciaCheque) => {
      const cheque: Cheque = new Cheque();
      cheque.numero = transferenciaCheque.chequeNumero;
      cheque.data = transferenciaCheque.chequeData;
      cheque.dataPreDatada = transferenciaCheque.chequeDataPreDatada;
      cheque.parceiro = transferenciaCheque.chequeParceiro;
      cheque.valor = transferenciaCheque.chequeValor;
      cheque.idLoja = transferenciaCheque.chequeIdLoja;
      cheque.abreviacao = transferenciaCheque.chequeAbreviacao;
      cheque.selecionado = transferenciaCheque.excluido ? 'N' : 'S';
      cheque.id = transferenciaCheque.idCheque;
      chequesSelecionados.push(cheque);
    });
    return chequesSelecionados;
  }
}
