import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const utilFiltro: UtilFiltro = new UtilFiltro();
const filtroComboModalidade: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'À VISTA', id: 'S' },
  { nome: 'A PRAZO', id: 'N' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataBaixaDe, nome: 'DATA_BAIXA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataBaixaAte, nome: 'DATA_BAIXA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.fornecedor, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.contaContabil, nome: 'IDS_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.natureza, nome: 'IDS_NATUREZA', coluna: 6, servicoWeb: bibServico.natureza, tipo: bibPropriedade.filtro.checklista },
  { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'IDS_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.contaBancaria, nome: 'IDS_CONTA_BANCARIA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
]);
export default filtros;
