<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(movimentacoes, $event)" (campoGrupoEmt)="agrupar(movimentacoes, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.dataHora }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-0-5">{{ bibDialogo.op }}<ajuda [ajuda]="bibDialogo.operacao"></ajuda></th>
      <th class="r-1">{{ bibDialogo.vend }}</th>
      <th class="r">{{ bibDialogo.cliente }}</th>
      <th class="r-1 text-right">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valorTabela }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      <th class="r-1 text-right">{{ bibDialogo.lucro }}</th>
      <th class="r-1 text-right">{{ bibDialogo.margem }}</th>
      <th *ngIf="usaNegociacao" class="r-1">{{ bibDialogo.negociacaoPrazoDiasAbreviado }}<ajuda [ajuda]="bibDialogo.negociacaoPrazoDias"></ajuda></th>
      <th class="r-1 text-right">{{ bibDialogo.descontoAbreviacao }}</th>
      <th *ngIf="movimentacoes.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="{'sem-borda' : movimentacao.expandido, 'atraso' : movimentacao.lucroValor === 0}">
          <td>{{ movimentacao.abreviacao }}</td>
          <td>{{ movimentacao.dataHora | data: 'dd/MM/yy HH:mm' }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td><atencao [atencao]="movimentacao.operacaoAbreviacao" [tipo]="movimentacao.operacaoAbreviacao == 'DV' ? bibPropriedade.atencao.roxo : bibPropriedade.atencao.discreto" [ajuda]="movimentacao.operacao"></atencao></td>
          <td>{{ movimentacao.colaborador + ' '}}<ajuda [ajudaDireita]="true" [ajuda]="movimentacao.colaboradorRazaoSocial" [posicao]="bibDialogo.esquerda"></ajuda></td>
          <td>{{ movimentacao.parceiroNumero + ' - ' + movimentacao.parceiro }}</td>
          <td class="text-right">{{ (movimentacao.operacaoAbreviacao == 'DV' ? movimentacao.custoTotal * -1 : movimentacao.custoTotal * 1) | monetario }}</td>
          <td class="text-right">{{ (movimentacao.operacaoAbreviacao == 'DV' ? movimentacao.valorTabelaTotal * -1 : movimentacao.valorTabelaTotal * 1) | monetario }}</td>
          <td class="text-right">{{ (movimentacao.operacaoAbreviacao == 'DV' ? movimentacao.valorTotal * -1 : movimentacao.valorTotal * 1) | monetario }}</td>
          <td class="text-right">{{ (movimentacao.operacaoAbreviacao == 'DV' ? movimentacao.lucroValor * -1 : movimentacao.lucroValor * 1) | monetario }}</td>
          <td class="text-right">{{ ((movimentacao.operacaoAbreviacao == 'DV' ? movimentacao.margem * -1 : movimentacao.margem * 1) | monetario) + '%' }}</td>
          <td *ngIf="usaNegociacao">{{ movimentacao.negociacaoPrazo + ' ' }}<ajuda [ajudaDireita]="true" [ajuda]="movimentacao.negociacao" [posicao]="bibDialogo.esquerda"></ajuda></td>
          <td class="text-right">{{ (movimentacao.descontoPercentual | monetario) + '%' }}</td>
          <td class="naoImprimir" *ngIf="movimentacoes.length > 0"><btnAdicional [icone]="movimentacao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(movimentacao)"></btnAdicional></td>
        </tr>
        <tr *ngIf="movimentacao.expandido" r1053itens [usaNegociacao]="usaNegociacao" [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [movimentacao]="movimentacao"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacoes.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupoFinal"></tr>
    </tfoot>
  </table>
  <atencao *ngIf="movimentacoesLucroZerado.length > 0" class="naoImprimir" [atencao]="bibDialogo.alertaRelatorio1053" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  <table class="tabela-complementar" *ngIf="movimentacoesLucroZerado.length > 0">
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-0-5">{{ bibDialogo.op }}<ajuda [ajuda]="bibDialogo.operacao"></ajuda></th>
      <th class="r">{{ bibDialogo.cliente }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoLucroZerado of movimentacoesLucroZerado; let i = index">
        <tr class="conteudo">
          <td>{{ movimentacaoLucroZerado.abreviacao }}</td>
          <td class="text-right">{{ movimentacaoLucroZerado.numero }}</td>
          <td><atencao [atencao]="movimentacaoLucroZerado.operacaoAbreviacao" [tipo]="movimentacaoLucroZerado.operacaoAbreviacao == 'DV' ? bibPropriedade.atencao.roxo : bibPropriedade.atencao.discreto" [ajuda]="movimentacaoLucroZerado.operacao"></atencao></td>
          <td>{{ movimentacaoLucroZerado.parceiroNumero + ' - ' + movimentacaoLucroZerado.parceiro }}</td>
          <td class="text-right">{{ (movimentacaoLucroZerado.operacaoAbreviacao == 'DV' ? movimentacaoLucroZerado.valorTotal * -1 : movimentacaoLucroZerado.valorTotal * 1) | monetario }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</relatorioLayout>
