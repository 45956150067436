<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(recebimentos, $event)" (campoGrupoEmt)="agrupar(recebimentos, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
      <tr>
        <th class="r-0-5">{{ bibDialogo.loja }}</th>
        <th class="r-1">{{ bibDialogo.emissao }}</th>
        <th class="r-1">{{ bibDialogo.vencimento }}</th>
        <th *ngIf="mostrarInformacoesRecebimento" class="r-1">{{ bibDialogo.recebimento }}<ajuda [ajuda]="bibDialogo.dataConciliacao"></ajuda></th>
        <th class="r-0-5 text-center">{{ bibDialogo.res }}</th>
        <th class="r limitar">{{ rotuloTipoTitulo }}</th>
        <th class="r-1">{{ bibDialogo.parcela }}</th>
        <th class="r-0-5">{{ bibDialogo.debitoCreditoAbreviacao }}</th>
        <th class="r-1">{{ bibDialogo.numeroAutorizacao }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorTotal }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorParcela }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorLiquido }}<ajuda [ajuda]="bibDialogo.valorParcelaMenosTaxa"></ajuda></th>
        <th *ngIf="mostrarInformacoesRecebimento" class="r-1 text-right">{{ bibDialogo.valorAReceber }}<ajuda [ajuda]="bibDialogo.valorAReceberCartao"></ajuda></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let recebimento of recebimentos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ recebimento.loja }}</td>
          <td>{{ recebimento.dataLancamento | data }}</td>
          <td>{{ recebimento.dataVencimento | data }}</td>
          <td *ngIf="mostrarInformacoesRecebimento">{{ recebimento.dataPagamento | data }}</td>
          <td class="text-center">{{ recebimento.responsavel }}</td>
          <td>{{ recebimento.tipoTitulo }}</td>
          <td>{{ recebimento.parcela }}</td>
          <td>{{ recebimento.idEspecie == 2 ? 'D' : 'C' }}</td>
          <td>{{ recebimento.numeroDocumento }}</td>
          <td class="text-right">{{ recebimento.valorTotal | monetario }}</td>
          <td class="text-right">{{ recebimento.valorParcela | monetario }}</td>
          <td class="text-right">{{ recebimento.valorLiquido | monetario }}</td>
          <td *ngIf="mostrarInformacoesRecebimento" class="text-right">{{ recebimento.valorAberto | monetario }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="recebimentos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
