import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - ' + bibDialogo.aguardandoFinanceiro, id: 2 },
  { nome: '02 - ' + bibDialogo.aguardandoEntrega, id: 3 },
  { nome: '03 - ' + bibDialogo.entregaParcial, id: 4 },
  { nome: '04 - ' + bibDialogo.concluida, id: 5 },
  { nome: '05 - ' + bibDialogo.aguardandoNfe, id: 100 },
  { nome: '06 - ' + bibDialogo.nfeTransmitida, id: 101 },
  { nome: '07 - ' + bibDialogo.todos, id: '-' }
]);

const filtroFinanceiro: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.nao, id: "'N'" },
  { nome: bibDialogo.sim, id: "'R', 'D'" },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtroModelos: FiltroCombo[] = plainToClass(FiltroCombo, [
  { id: 'TO', nome: '00 - TODOS QUE TEM MODELO' },
  { id: '01', nome: '01 - NOTA FISCAL' },
  { id: '1B', nome: '1B - NOTA FISCAL AVULSA' },
  { id: '02', nome: '02 - NOTA FISCAL DE VENDA A CONSUMIDOR' },
  { id: '2D', nome: '2D - CUPOM FISCAL EMITIDO POR ECF' },
  { id: '2E', nome: '2E - BILHETE DE PASSAGEM EMITIDO POR ECF' },
  { id: '04', nome: '04 - NOTA FISCAL DE PRODUTOR' },
  { id: '06', nome: '06 - NOTA FISCAL/CONTA DE ENERGIA ELÉTRICA' },
  { id: '07', nome: '07 - NOTA FISCAL DE SERVIÇO DE TRANSPORTE' },
  { id: '08', nome: '08 - CONHECIMENTO DE TRANSPORTE RODOVIÁRIO DE CARGAS' },
  { id: '8B', nome: '8B - CONHECIMENTO DE TRANSPORTE DE CARGAS AVULSO' },
  { id: '09', nome: '09 - CONHECIMENTO DE TRANSPORTE AQUAVIÁRIO DE CARGAS' },
  { id: '10', nome: '10 - CONHECIMENTO AÉREO' },
  { id: '11', nome: '11 - CONHECIMENTO DE TRANSPORTE FERROVIÁRIO DE CARGAS' },
  { id: '13', nome: '13 - BILHETE DE PASSAGEM RODOVIÁRIO' },
  { id: '14', nome: '14 - BILHETE DE PASSAGEM AQUAVIÁRIO' },
  { id: '15', nome: '15 - BILHETE DE PASSAGEM E NOTA DE BAGAGEM' },
  { id: '17', nome: '17 - DESPACHO DE TRANSPORTE' },
  { id: '16', nome: '16 - BILHETE DE PASSAGEM FERROVIÁRIO' },
  { id: '18', nome: '18 - RESUMO DE MOVIMENTO DIÁRIO' },
  { id: '20', nome: '20 - ORDEM DE COLETA DE CARGAS' },
  { id: '21', nome: '21 - NOTA FISCAL DE SERVIÇO DE COMUNICAÇÃO' },
  { id: '22', nome: '22 - NOTA FISCAL DE SERVIÇO DE TELECOMUNICAÇÃO' },
  { id: '23', nome: '23 - GNRE' },
  { id: '24', nome: '24 - AUTORIZAÇÃO DE CARREGAMENTO E TRANSPORTE' },
  { id: '25', nome: '25 - MANIFESTO DE CARGA' },
  { id: '26', nome: '26 - CONHECIMENTO DE TRANSPORTE MULTIMODAL DE CARGAS' },
  { id: '27', nome: '27 - NOTA FISCAL DE TRANSPORTE FERROVIÁRIO DE CARGAS' },
  { id: '28', nome: '28 - NOTA FISCAL/CONTA DE FORNECIMENTO DE GÁS CANALIZADO' },
  { id: '29', nome: '29 - NOTA FISCAL/CONTA DE FORNECIMENTO DE ÁGUA CANALIZADA' },
  { id: '30', nome: '30 - BILHETE/RECIBO DO PASSAGEIRO' },
  { id: '55', nome: '55 - NOTA FISCAL ELETRÔNICA' },
  { id: '57', nome: '57 - CONHECIMENTO DE TRANSPORTE ELETRÔNICO – CT-E' },
  { id: '65', nome: '65 - NOTA FISCAL DE CONSUMIDOR ELETRÔNICA (NFC-E)' },
  { id: '66', nome: '66 - NOTA FISCAL DE ENERGIA ELÉTRICA ELETRÔNICA – NF3E' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.clienteFornecedor, nome: 'ID_PARCEIRO', coluna: 6, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.usuario, nome: 'ID_USU_INCLUSAO', coluna: 6, tipo: bibPropriedade.filtro.busca, servicoWeb: bibServico.usuario },
  { rotulo: bibDialogo.negociacao, nome: 'ID_NEGOCIACAO', coluna: 6, servicoWeb: bibServico.negociacao, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.operacao, nome: 'IDS_OPERACAO', coluna: 6, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.situacao, nome: 'MOVIMENTACAO_SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroSituacao },
  { rotulo: bibDialogo.modelo, nome: 'MODELO', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroModelos },
  { rotulo: bibDialogo.gerouFinanceiro, nome: 'FINANCEIRO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.lista, valor: '-', lista: filtroFinanceiro },
  { rotulo: bibDialogo.naturezaOperacao, nome: 'IDS_NATUREZA_OPERACAO', coluna: 6, servicoWeb: bibServico.naturezaOperacao, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.centroResultado, nome: 'IDS_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.safra, nome: 'ID_SAFRA', coluna: 6, servicoWeb: bibServico.safra, tipo: bibPropriedade.filtro.busca },
]);
export default filtros;
