<layout [subTitulo]="contaNome ? bibDialogo.conta.toUpperCase() + ': ' + contaNome : ''" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div class="row justify-content-between">
    <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="financeiros.length"></registro>
    <span *ngIf="financeiros.length > 0 && dataAtualizacao" class="oi oi-media-record pulse margem-icone-atualizacao text-success"></span>
    <span *ngIf="financeiros.length > 0 && dataAtualizacao" class="text-uppercase">{{ bibDialogo.dataAtualizacao + ': ' + (dataAtualizacao | data: 'dd/MM/yyyy HH:mm:ss') }} </span>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"><check *ngIf="financeiros.length > 0" (alteracao)="selecionarFinanceiros($event)" [campo]="selecionado" [ajuda]="bibDialogo.marcarTodos"></check></th>
        <th class="l-5"></th>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-5 clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroAbreviado }}</th>
        <th class="l-10 clique" (click)="listar(add('dataVencimento'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.vencimento }}</th>
        <th class="l-20 clique" (click)="listar(add('nomeParceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cliente }}</th>
        <th class="l-10 clique" (click)="listar(add('numeroConta'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.conta }}</th>
        <th class="l-10 clique" (click)="listar(add('numeroBanco'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.banco }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorRestante'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valor }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let financeiro of financeiros; let i = index">
        <tr [ngClass]="financeiro.difereBoleto ? 'bg-amarelo-claro' : ''">
          <td>
            <check *ngIf="(!financeiro.financeiroBoleto || financeiro.financeiroBoleto?.status == 0 || financeiro.financeiroBoleto?.status == 3) && (financeiro.tipo == 'R' || (financeiro.valorRestante < valorTotalBoleto || financeiro.selecionado == 'S')) ? true : false" (alteracao)="setFinanceiroSelecionado(financeiro)" [campo]="financeiro.selecionado"></check>
            <icone class="d-flex align-items-center justify-content-center" *ngIf="financeiro.difereBoleto" [icone]="bibIcone.atencao" [cor]="bibPropriedade.icone.preto" [mensagem]="bibDialogo.boletoAAlterar"></icone>
          </td>
          <td>
            <icone *ngIf="financeiro.financeiroBoleto?.situacao != 'LIQUIDADO'" class="col-sm d-flex align-items-center" [icone]="bibIcone.circulo" [cor]="financeiro.financeiroBoleto?.status == 135 ? bibPropriedade.icone.preto : financeiro.financeiroBoleto?.status == -3 ? bibPropriedade.icone.laranja : financeiro.financeiroBoleto?.status == 20 ? bibPropriedade.icone.azul : financeiro.financeiroBoleto?.status == 3 ? bibPropriedade.icone.vermelho : financeiro.financeiroBoleto?.status == -1 ? bibPropriedade.icone.amarelo : financeiro.financeiroBoleto?.status == 100 ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="financeiro.financeiroBoleto?.status == 135 ? bibDialogo.cancelada : financeiro.financeiroBoleto?.status == -3 ? bibDialogo.cancelamentoEmAndamento : financeiro.financeiroBoleto?.status == 20 ? bibDialogo.emProcessoRegistro : financeiro.financeiroBoleto?.status == 3 ? bibDialogo.naoAutorizado : financeiro.financeiroBoleto?.status == -1 ? bibDialogo.emAndamento : financeiro.financeiroBoleto?.status == 100 ? bibDialogo.transmitidaComSucesso : bibDialogo.naoEnviada" [ajudaDireita]="true"></icone>
            <sup [ngbTooltip]="financeiro.financeiroBoleto?.statusDescricao" *ngIf="financeiro.financeiroBoleto?.situacao == 'LIQUIDADO'" class="ml-1 mt-2 badge" class="text-success">{{ bibDialogo.abreviacaoPago }}</sup>
          </td>
          <td>{{ financeiro.abreviacao }}</td>
          <td>{{ financeiro.financeiroBoleto?.numero }}</td>
          <td>{{ financeiro.dataVencimento | data }}</td>
          <td class="limitar fonte-menor">
            <a href="#" (click)="abrirModalFinanceiro(financeiro.id)">{{ financeiro.nomeParceiro }}<ajuda class="margem-ajuda cor" [ajuda]="financeiro.clienteNomeFantasia"></ajuda></a>
          </td>
          <td class="limitar" [ngClass]="contas[0]?.homologacao == 'S' ? 'tachado' : ''">{{ financeiro.numeroConta }}<ajuda *ngIf="contas[0]?.homologacao == 'S'" class="margem-ajuda" [ajuda]="bibDialogo.contaAmbienteHomologacao"></ajuda></td>
          <td><img *ngIf="financeiro.numeroBanco" src="img/Boletos/Colorido/{{ financeiro.numeroBanco }}.bmp" class="logomarca" /></td>
          <td class="text-right" [ngClass]="financeiro.tipo == 'D' ? 'text-danger' : ''">{{ financeiro.valorRestante != 0 ? (financeiro.valorRestante | monetario) : (financeiro.financeiroBoleto?.valor | monetario) }}</td>
          <td><btnAdicional *ngIf="financeiro.financeiroBoleto && (financeiro.financeiroBoleto?.status == -1 || financeiro.financeiroBoleto?.status == 3 && financeiro.financeiroBoleto?.situacao.toLowerCase() == bibDialogo.boletoNaoProcessado.toLowerCase())" [icone]="bibIcone.lupa" [ajuda]="bibDialogo.consultarBoleto" (btnAdicional)="consultarBoletoTecnospeed(financeiro.financeiroBoleto)"></btnAdicional></td>
          <td><btnAdicional *ngIf="financeiro.financeiroBoleto && (financeiro.financeiroBoleto?.status == 3 || financeiro.financeiroBoleto?.status == 20 || financeiro.financeiroBoleto?.status == 100 || financeiro.financeiroBoleto?.status == 135)" [icone]="financeiro.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="financeiro.expandido = !financeiro.expandido" [desabilitado]="financeiro.financeiroBoleto?.status != -1 && financeiro.financeiroBoleto?.status != 0 ? false : true"></btnAdicional></td>
          <td><btnAdicional *ngIf="financeiro.financeiroBoleto && (financeiro.financeiroBoleto?.status == 20 || financeiro.financeiroBoleto?.status == 100) && financeiro.financeiroBoleto?.situacao != 'LIQUIDADO'" [icone]="bibIcone.pdf" [ajuda]="bibDialogo.pdf" (btnAdicional)="desejaGerarPdfBoletoDiferenteFinanceiro(financeiro.financeiroBoleto)"></btnAdicional></td>
          <td><btnAdicional *ngIf="financeiro.financeiroBoleto && (financeiro.financeiroBoleto?.status == 20 || financeiro.financeiroBoleto?.status == 100) && financeiro.financeiroBoleto?.situacao != 'LIQUIDADO'" [icone]="bibIcone.atencao" [ajuda]="bibDialogo.enviarSolicitacaoCancelamento" (btnAdicional)="enviarCancelamento(financeiro.financeiroBoleto)"></btnAdicional></td>
          <td><btnAdicional *ngIf="financeiro.financeiroBoleto && (financeiro.financeiroBoleto?.status == 20 || financeiro.financeiroBoleto?.status == 100) && financeiro.financeiroBoleto?.situacao != 'LIQUIDADO'" [icone]="bibIcone.carta" [ajuda]="bibDialogo.enviarBoletoDestinatario" (btnAdicional)="ehEnviarEmail(financeiro.idParceiro, financeiro.financeiroBoleto?.idTecnospeed)"></btnAdicional></td>
          <td><btnAdicional *ngIf="financeiro.financeiroBoleto && (financeiro.financeiroBoleto?.status == -3 || financeiro.financeiroBoleto?.status == 20  || financeiro.financeiroBoleto?.status == 100) && financeiro.financeiroBoleto?.situacao != 'LIQUIDADO'" [icone]="bibIcone.lupa" [ajuda]="bibDialogo.consultarBoleto" (btnAdicional)="consultarBoletoEmProssessamento(financeiro.financeiroBoleto)"></btnAdicional></td>
          <td><btnAdicional *ngIf="financeiro.difereBoleto" [icone]="bibIcone.borracha" [ajuda]="bibDialogo.alterarBoleto" (btnAdicional)="alterarBoleto(financeiro.financeiroBoleto)"></btnAdicional></td>
          <td><btnAdicional *ngIf="financeiro.financeiroBoleto && financeiro.financeiroBoleto?.statusDescricao == 'GERADA' && financeiro.financeiroBoleto?.protocoloCancelamento != null" [icone]="bibIcone.prancheta" [ajuda]="bibDialogo.baixarRemessa" (btnAdicional)="baixarRemessa(financeiro.financeiroBoleto)"></btnAdicional>
            <btnAdicional *ngIf="financeiro.financeiroBoleto && financeiro.financeiroBoleto?.status == 135 && financeiro.idFinanceiroBoleto != null" [icone]="bibIcone.clone" [ajuda]="bibDialogo.reutilizarFinanceiroNovoBoleto" (btnAdicional)="reutilizarBoletoCancelado(financeiro)"></btnAdicional>
          </td>
        </tr>
        <td class="border-0" colspan="16" *ngIf="utilSessao.getUsuario()?.agrow == 'S'">
          <div class="justify-content-end p-0">
            <p class="clique fonte-menor" (click)="abrirJsonConsulaBoleto(financeiro.financeiroBoleto.idTecnospeed)">{{ bibDialogo.visualizarJsonBoleto }}</p>
          </div>
        </td>
        <ng-container *ngIf="financeiro.expandido">
          <tr *ngIf="financeiro.financeiroBoleto?.status == 3">
            <td colspan="15"><descricao [rotulo]="bibDialogo.rejeicao" [campo]="financeiro.financeiroBoleto?.statusDescricao" [normal]="true" [maximoCaracteres]="1500" [desabilitado]="true"></descricao></td>
          </tr>
          <ng-container *ngIf="financeiro.financeiroBoleto?.status == 135 || financeiro.financeiroBoleto?.status == 20 || financeiro.financeiroBoleto?.status == 100">
            <tr>
              <td colspan="10"><apresentacao [rotulo]="bibDialogo.status" [campo]="financeiro.financeiroBoleto?.situacao"> </apresentacao></td>
              <td colspan="5"><apresentacao [rotulo]="bibDialogo.dataVencimento" [campo]="financeiro.dataVencimento | data"></apresentacao></td>
            </tr>
            <tr>
              <td colspan="10"><apresentacao [rotulo]="bibDialogo.descricao" [campo]="financeiro.financeiroBoleto?.statusDescricao"> </apresentacao></td>
              <td colspan="5" *ngIf="financeiro.financeiroBoleto?.dataRemessa"><apresentacao [rotulo]="bibDialogo.dataRemessa" [campo]="financeiro.financeiroBoleto?.dataRemessa | data"></apresentacao></td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
  <totalizador *ngIf="valorTotalBoleto == 0 && contagemBoletoAlterar > 0" [atencaoTotalizador]="bibDialogo.boletoAAlterar" [tipoAtencaoTotalizador]="bibPropriedade.atencao.alerta" [valorTotalizador]="contagemBoletoAlterar" [tipoTotalizador]="bibPropriedade.cor.vermelho"></totalizador>
  <totalizador *ngIf="valorTotalBoleto > 0" [atencaoTotalizador]="bibDialogo.valorTotal" [tipoAtencaoTotalizador]="bibPropriedade.atencao.alerta" [valorTotalizador]="valorTotalBoleto | monetario"></totalizador>
</layout>
<div *ngIf="financeiros.length > 0" class="d-flex flex-row align-items-center justify-content-between">
  <div class="row p-0 m-0">
    <botao [compacto]="compacto" [legenda]="contas[0]?.homologacao == 'S' ? bibDialogo.emitirBoletoHomologacao : bibDialogo.emitirBoleto" [tipo]="contas[0]?.homologacao == 'S' ? bibPropriedade.botao.cuidado : bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirFinanceiroBoleto()"></botao>
    <botao *ngIf="this.listouPorParceiro == true" [compacto]="compacto" [legenda]="bibDialogo.emitirBoleto + '  ' + bibDialogo.unificado.toLowerCase()" [fundo]="true" (botaoEmt)="persistirFinanceiroBoleto(true)"></botao>
  </div>
  <div class="row m-0 p-0 align-content-center">
    <atencao *ngIf="contagemBoletoAlterar > 0" [atencao]="bibDialogo.boletoAAlterar" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
