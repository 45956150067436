export class ConfiguracaoMovimentacaoTotalContabil {
  public alterado: boolean;
  public contaContabil: string;
  public contaCredito: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public debitoCredito: number;
  public historico: string;
  public historicoContrapartida: string;
  public id: number;
  public idConfMovimentacaoContabil: number;
  public idContaContabil: number;
  public idContaContabilContrapartida: number;
  public idEmpresa: number;
  public idTipoLancamentoContabil: number;
  public idTotalMovimentacaoContabil: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public lancamentoDireto: string = 'S';
  public origem: number = 1;
  public tipoLancamentoContabil: string;
  public totalMovimentacaoContabil: string;
  public totalContabilContraPartida: string;

  public numeroReduzido: number;
}
