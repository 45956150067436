import { Component, ViewChild } from '@angular/core';
import { BibServicoMdfeTecnoSpeed } from 'src/app/biblioteca/bibServicoMdfeTecnoSpeed';
import { bibServicoTecnoSpeed } from 'src/app/biblioteca/bibServicoTecnoSpeed';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Cidade } from 'src/app/modelo/cidade';
import { ConfiguracaoFinanceiroContabil } from 'src/app/modelo/configuracaoFinanceiroContabil';
import { ConfiguracaoTransferenciaContabil } from 'src/app/modelo/configuracaoTransferenciaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { Loja } from 'src/app/modelo/loja';
import { LojaEstado } from 'src/app/modelo/lojaEstado';
import { Praca } from 'src/app/modelo/praca';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoLancamentoContabil } from 'src/app/modelo/tipoLancamentoContabil';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './lojaFrm.component.html',
})
export class LojaFrmComponent extends PaginaComponent {
  @ViewChild('arquivoUpload') arquivoUpload;
  public alterouMdfeAmbiente: boolean = false;
  public alterouMdfeTipoEnvio: boolean = false;
  public apresentaLojaValorLojaMatriz: boolean = false;
  public apresentaLoja: string;
  public apresentarAtencao: boolean = false;
  public configuracaoFinanceiroContabeis: ConfiguracaoFinanceiroContabil[] = [];
  public configuracaoFinanceiroContabeisDespesas: ConfiguracaoFinanceiroContabil[] = [];
  public configuracaoFinanceiroContabeisReceitas: ConfiguracaoFinanceiroContabil[] = [];
  public configuracaoTransferenciaContabeis: ConfiguracaoTransferenciaContabil[] = [];
  public tipoLancamentoContabeis: TipoLancamentoContabil[] = [];
  public bibServicoTecnoSpeed: bibServicoTecnoSpeed = new bibServicoTecnoSpeed();
  public edicaoLojaEstados: number[] = [];
  public excluirLojaEstados: number[] = [];
  public imagemEmpresa: string = 'img/empresa-semfoto.jpg';
  public loja: Loja = new Loja();
  public lojaValores: Loja[] = [];
  public lojaMatriz: Loja[] = [];
  public pracas: Praca[] = [];
  public conexao: Conexao = new Conexao(this.utilSessao);
  public data: Date = new Date();
  public empresa: Empresa = this.utilSessao.getEmpresa();
  public bloqueiaResponsavel: boolean = false;

  public listaEnquadramento: any = [
    { id: '2', nome: this.bibDialogo.lucroPresumido.toUpperCase() },
    { id: '3', nome: this.bibDialogo.lucroReal.toUpperCase() },
    { id: '1', nome: this.bibDialogo.simplesNacional.toUpperCase() },
    { id: '4', nome: this.bibDialogo.simplesNacionalExcesso.toUpperCase() },
  ];

  public listaPessoas: any[] = [
    { id: 'F', nome: this.bibDialogo.fisica.toUpperCase() },
    { id: 'J', nome: this.bibDialogo.juridica.toUpperCase() },
  ];

  public listaSimNao: any[] = [
    { id: 'N', nome: this.bibDialogo.nao.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
  ];

  public listaInventario: any[] = [
    { id: 'N', nome: this.bibDialogo.inventarioSpedTradicional.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.inventarioSpedMensalmente.toUpperCase() },
    { id: 'T', nome: this.bibDialogo.inventarioSpedInclusiveFevereiro.toUpperCase() },
  ];

  public listaIbpt: any[] = [
    { id: 'S', nome: this.bibDialogo.ibptSempreEnviar.toUpperCase() },
    { id: 'C', nome: this.bibDialogo.ibptSomenteConsumidorFinal.toUpperCase() },
    { id: 'N', nome: this.bibDialogo.ibptNuncaEnviar.toUpperCase() },
  ];

  public listaMargemDiferenciada: any[] = [
    { id: 'N', nome: this.bibDialogo.margemTradicional.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.margemDiferenciada.toUpperCase() },
  ];

  public listaTipoDeAtividade: any[] = [
    { id: 100, nome: this.bibDialogo.industrialEquiparado.toUpperCase() },
    { id: 1, nome: this.bibDialogo.prestadorDeServicos.toUpperCase() },
    { id: 2, nome: this.bibDialogo.atividadeDeComercio.toUpperCase() },
    { id: 3, nome: this.bibDialogo.atividadeFinanceira.toUpperCase() },
    { id: 4, nome: this.bibDialogo.atividadeImobiliaria.toUpperCase() },
    { id: 9, nome: this.bibDialogo.outros.toUpperCase() },
  ];

  public listaIncidendiaTributaria: any[] = [
    { id: 1, nome: this.bibDialogo.escrituracaoRegimeNaoCumulativo.toUpperCase() },
    { id: 2, nome: this.bibDialogo.escrituracaoRegimeCumulativo.toUpperCase() },
    { id: 3, nome: this.bibDialogo.escrituracaoRegimeCumulativoNaoCumulativo.toUpperCase() },
  ];

  public listaCreditosComuns: any[] = [
    { id: 100, nome: this.bibDialogo.nenhum.toUpperCase() },
    { id: 1, nome: this.bibDialogo.metodoApropriacaoDireta.toUpperCase() },
    { id: 2, nome: this.bibDialogo.metodoRateioProporcional.toUpperCase() },
  ];

  public listaContribuicaoApurada: any[] = [
    { id: 100, nome: this.bibDialogo.nenhum.toUpperCase() },
    { id: 1, nome: this.bibDialogo.apuracaoContribuicaoExclusiva.toUpperCase() },
    { id: 2, nome: this.bibDialogo.apuracaoContribuicaoEspecifica.toUpperCase() },
  ];

  public listaApuracaoAdotado: any[] = [
    { id: 100, nome: this.bibDialogo.nenhum.toUpperCase() },
    { id: 1, nome: this.bibDialogo.regimeCaixa.toUpperCase() },
    { id: 2, nome: this.bibDialogo.regimeCompetencia.toUpperCase() },
    { id: 9, nome: this.bibDialogo.regimeCompetenciaDetalhada.toUpperCase() },
  ];

  public listaMdfeEnvio: any[] = [
    { id: 'NORMAL', nome: this.bibDialogo.normal.toUpperCase() },
    { id: 'CONTINGENCIA', nome: this.bibDialogo.contingencia.toUpperCase() },
  ];
  S
  public listaPerfil: any[] = [
    { id: 'A', nome: this.bibDialogo.perfilA.toUpperCase() },
    { id: 'B', nome: this.bibDialogo.perfilB.toUpperCase() },
  ];

  public listaMdfeAmbiente: any[] = [
    { id: 1, nome: this.bibDialogo.producao.toUpperCase() },
    { id: 2, nome: this.bibDialogo.homologacao.toUpperCase() },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.loja = this.plainToClass(Loja, this.ehAtualizacao(this.loja));
    if (this.loja.id) {
      this.listarLoja(this.loja.id);
    }
    this.listarPraca();
    this.listarLojas();
    this.listarConfiguracaoTransferenciaContabil();
    this.listarConfiguracaoFinanceiroContabil();
    this.listarTipoTransferenciaContabil();
  }

  verificarTipoPessoaContador(): void {
    if (this.loja.contadorCpf && this.loja.contadorCpf.length >= 14) {
      this.loja.contadorPessoaJuridica = 'S';
    } else {
      this.loja.contadorPessoaJuridica = 'N';
    }
  }

  listarLojas(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('IDS_DESCONSIDERAR', this.loja.id));
    criterios.push(new Criterio('ATIVO', 'S'));

    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.loja).subscribe((res) => {
      this.lojaValores = this.plainToClass(Loja, res) as any;
      this.lojaMatriz = this.plainToClass(Loja, res) as any;
      this.apresentaLojaValorLojaMatriz = this.lojaValores.length == 0 ? false : true;
    });
  }

  listarConfiguracaoFinanceiroContabil(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.configuracaoFinanceiroContabil).subscribe((res) => {
      this.configuracaoFinanceiroContabeis = this.plainToClass(ConfiguracaoFinanceiroContabil, res) as any;
      this.configuracaoFinanceiroContabeisDespesas = this.configuracaoFinanceiroContabeis.filter((configuracaoFinanceiroContabil) => configuracaoFinanceiroContabil.tipo == 'D');
      this.configuracaoFinanceiroContabeisReceitas = this.configuracaoFinanceiroContabeis.filter((configuracaoFinanceiroContabil) => configuracaoFinanceiroContabil.tipo == 'R');
    });
  }

  listarConfiguracaoTransferenciaContabil(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.configuracaoTransferenciaContabil).subscribe((res) => {
      this.configuracaoTransferenciaContabeis = this.plainToClass(ConfiguracaoTransferenciaContabil, res) as any;
    });
  }

  listarTipoTransferenciaContabil(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.tipoLancamentoContabil).subscribe((res) => {
      this.tipoLancamentoContabeis = this.plainToClass(TipoLancamentoContabil, res) as any;
    });
  }

  listarLoja(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.loja).subscribe((res) => {
      this.loja = this.plainToClass(Loja, res[0]) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_LOJA', id)), this.bibServico.lojaEstado).subscribe((res) => {
        this.loja.lojaEstados = this.plainToClass(LojaEstado, res);
        this.loja.historicoFoto = this.loja.foto;
        this.apresentaLoja = !this.loja.idLojaValor ? '' : this.loja.idLojaValor == this.loja.id ? 'N' : 'S';
        this.verificarTipoPessoaContador();
        this.verificarMarcacao();
      });
    });
  }

  listarPraca(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.praca).subscribe((res) => {
      this.pracas = this.plainToClass(Praca, res);
    });
  }

  lojaJuroPercentual(juroPercentual: number): void {
    this.loja.juroPercentual = juroPercentual;
    this.apresentarAtencao = true;
  }

  lojaCarenciaDias(carenciaDias: number): void {
    this.loja.carenciaDias = carenciaDias;
    this.apresentarAtencao = true;
  }

  setContadorCnpjCpf(loja: Loja, valor: string): void {
    loja.contadorCpf = valor == '' ? null : valor;
    loja.ehValidoCnpjCpf = this.util.validarCnpjCpf(loja.contadorCpf);
  }

  setEnviarIbptNfe(loja: Loja, ibpt: string): void {
    loja.enviarIbptNfe = ibpt == '' ? null : ibpt;
  }

  setContadorCidade(cidade: Cidade): void {
    this.loja.contadorIdCidade = cidade.id;
    this.loja.contadorCidade = cidade.nome;
  }

  setBuscaPrecoOutraLoja(buscaPrecoLoja: string): void {
    this.apresentaLoja = buscaPrecoLoja;
    if (this.apresentaLoja == 'N') {
      this.loja.idLojaValor = this.loja.id;
    } else {
      const indiceLoja: number = this.lojaValores.findIndex((lojaValor) => lojaValor.id == this.loja.id);
      if (indiceLoja != -1) {
        this.lojaValores.splice(indiceLoja, 1);
      }
      this.loja.idLojaValor = null;
    }
  }

  apenasNumeros(valor: string): string {
    let numsStr = valor.replace(/[^0-9]/g, '');
    return numsStr;
  }

  mudarAmbienteMdfeTecnospeed() {
    let bibServicoMdfeTecnoSpeed: BibServicoMdfeTecnoSpeed = new BibServicoMdfeTecnoSpeed();
    this.comunicacaoTecnoSpeedMDFeService.persistirLoja(bibServicoMdfeTecnoSpeed.getUrlAmbiente('mdfe'), 'MDFE', 'Ambiente', this.loja.mdfeAmbiente, this.apenasNumeros(this.loja.cnpj)).subscribe();
  }

  mudarTipoEnvioMdfeTecnospeed() {
    let bibServicoMdfeTecnoSpeed: BibServicoMdfeTecnoSpeed = new BibServicoMdfeTecnoSpeed();
    this.comunicacaoTecnoSpeedMDFeService.persistirLoja(bibServicoMdfeTecnoSpeed.getUrlAmbiente('mdfe'), 'MDFE', 'ModoOperacao', this.loja.mdfeEnvio, this.apenasNumeros(this.loja.cnpj)).subscribe();
  }

  persistirLoja(): void {
    if (this.ehValido()) {
      if (this.alterouMdfeAmbiente) {
        this.mudarAmbienteMdfeTecnospeed();
      }
      if (this.alterouMdfeTipoEnvio) {
        this.mudarTipoEnvioMdfeTecnospeed();
      }
      const transporte: Transporte = new Transporte(this.loja);
      transporte.adicionar(this.excluirLojaEstados);
      super.persistir(transporte, this.bibServico.loja, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.loja], this.bibClasse.loja, true);
          this.definirId(this.loja.lojaEstados, this.bibClasse.lojaEstado);
          this.excluirLojaEstados = [];
          this.edicaoLojaEstados = [];
          this.utilSessao.alertaAlteracao = true;
        }
      });
    }
  }

  ehValidoAliquotaInterna(): boolean {
    let contagemAliquotaInterna: number = 0;
    let mensagem: string = '';
    if (this.loja.lojaEstados.length > 0) {
      this.loja.lojaEstados.forEach((lojaEstado) => {
        if (lojaEstado.aliquotaInterna != null) {
          if (lojaEstado.aliquotaInterna != 4 && lojaEstado.aliquotaInterna != 7 && lojaEstado.aliquotaInterna != 12) {
            mensagem += mensagem == '' ? '' : ', ';
            mensagem += lojaEstado.estado;
            contagemAliquotaInterna++;
          }
        }
      });
    }
    if (contagemAliquotaInterna > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.estadoLojaAliquotaInterna + ' ' + this.bibDialogo.aliquotasPermitidas + ' ' + this.bibDialogo.ajudaAliquotaInterna, [mensagem])));
      return false;
    }
    return true;
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoEndereco: boolean = this.ehValidoObrigatorioEndereco();
    const ehValidoCnpjLoja: boolean = this.ehValidoCnpjCpf(this.loja.cnpj);
    const ehValidoCnpjCpf: boolean = this.setEhValidoCnpjCpf();
    const ehValidoDataAbertura: boolean = this.setEhValidoDataAbertura();
    const ehValidoAliquotaInterna: boolean = this.ehValidoAliquotaInterna();
    return ehValidoObrigatorio && ehValidoEndereco && ehValidoCnpjLoja && ehValidoCnpjCpf && ehValidoDataAbertura && ehValidoAliquotaInterna;
  }

  ehValidoObrigatorio(): boolean {
    if (this.loja.razaoSocial && this.loja.telefone && this.loja.nomeFantasia && this.loja.idPraca && this.loja.abreviacao && this.loja.cnpj && this.loja.inscricaoEstadual && this.loja.enquadramento && (this.loja.juroPercentual || this.loja.juroPercentual == 0) && (this.loja.regraVariacaoCusto || this.loja.regraVariacaoCusto == 0) && this.loja.contador && this.loja.idLojaValor && this.loja.contadorIdCidade && (this.loja.contadorEmail?.length == 0 || this.loja.contadorEmail == null) == false && this.loja.spedFiscalPerfil) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoObrigatorioEndereco(): boolean {
    if (this.loja.endereco && this.loja.enderecoNumero && this.loja.idBairro && this.loja.idCidade && this.loja.cep && this.loja.enderecoTipo) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoCnpjCpf(cnpjCpf: string): boolean {
    if (this.util.validarCnpjCpf(cnpjCpf)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cnpjIncorreto));
    return false;
  }

  setEhValidoCnpjCpf(): boolean {
    if (this.util.validarCnpjCpf(this.loja.contadorCpf)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cpfIncorreto));
    return false;
  }

  setEhValidoDataAbertura(): boolean {
    if (this.loja.dataAbertura) {
      if (this.loja.dataAbertura > this.data) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataAberturaIncorreta));
        return false;
      }
    }
    return true;
  }

  alterarFoto(evento: any): void {
    const loja = this.loja;
    this.loja.historicoFoto = 'N';
    const leitorArquivo: FileReader = new FileReader();
    const imagem = <HTMLInputElement>document.getElementById('output');
    leitorArquivo.onload = function () {
      imagem.src = leitorArquivo.result.toString();
    };
    leitorArquivo.readAsDataURL(evento.target.files[0]);
    imagem.addEventListener('load', function () {
      loja.foto = imagem.src.indexOf('img/semfoto.jpg') == -1 ? 'S' : 'N';
      loja.fotoLoja = imagem.src.indexOf('img/semfoto.jpg') == -1 ? imagem.src : null;
    });
    this.arquivoUpload.nativeElement.value = '';
  }

  deletarFoto(): void {
    this.loja.foto = 'N';
    this.loja.fotoLoja = null;
  }

  setAmbiente(loja: Loja, valor: number) {
    loja.mdfeAmbiente = valor;
    this.alterouMdfeAmbiente = true;
  }

  setTipoEnvio(loja: Loja, valor: string) {
    loja.mdfeEnvio = valor;
    this.alterouMdfeTipoEnvio = true;
  }

  setNfeMensagem(mensagem: string) {
    this.loja.nfeMensagem = mensagem;
    this.verificarMarcacao();
  }

  flagResponsavel() {
    if (!this.loja.nfeMensagem) {
      this.setNfeMensagem(null);
    }
    this.loja.nfeMensagem == null ? (this.loja.nfeMensagem = '<<RESPONSAVEL>>') : (this.loja.nfeMensagem += ' ' + '<<RESPONSAVEL>>');
    this.verificarMarcacao();
  }

  verificarMarcacao(): void {
    if (this.loja.nfeMensagem != null) {
      this.bloqueiaResponsavel = this.loja.nfeMensagem.indexOf('<<RESPONSAVEL>>') > -1 ? true : false;
    }
  }
}
