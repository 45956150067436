<layout [titulo]="objeto.nome" [id]="objeto.id">
  <div class="row">
    <texto class="col-sm-12" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="objeto.nome" (alteracao)="objeto.nome = $event" [obrigatorio]="true" [foco]="true" [maximoCaracteres]="100"></texto>
    <lista *ngIf="servico == bibServico.configuracaoFinanceiroContabil" class="col-sm-4" [id]="'receitaDespesa'" [rotulo]="bibDialogo.tipo" [campo]="objeto.tipo" [lista]="receitaDespesa" (alteracao)="objeto.tipo = $event.id" [obrigatorio]="true"></lista>
  </div>
  <div class="row align-items-center">
    <titulo class="col-sm" [titulo]="bibDialogo.configuracao"></titulo>
    <botao class="text-right" [id]="'adicionarConfiguracao'" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.configuracao.toLowerCase()" (botaoEmt)="abrirModal(null)"></botao>
  </div>
  <div class="row" *ngIf="objetoTotais.length == 0">
    <p class="col-sm-12 pl-3">
      {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.configuracao.toLowerCase() + ', ' }} <strong class="clique" (click)="abrirModal(null)">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
    </p>
  </div>
  <table class="table table-responsive-sm" *ngIf="objetoTotais.length > 0">
    <thead>
      <tr>
        <th class="15">{{ bibDialogo.totalizador }}</th>
        <th class="text-right l-10" >{{ bibDialogo.nReduzido }}</th>
        <th [ngClass]="this.servico == this.bibServico.configuracaoFinanceiroContabil ? '30' : '45'">{{ bibDialogo.conta }}</th>
        <th class="l-15">{{ bibDialogo.debitoECredito }}</th>
        <th class="l-15">{{ menuTipoLancamentoContabil.apelido }}</th>
        <th class="l-15">{{ bibDialogo.lancamentoDireto }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let objetoTotal of objetoTotais; let i = index">
        <td class="limitar">{{ servico == bibServico.configuracaoMovimentacaoContabil ? objetoTotal.totalMovimentacaoContabil : servico == bibServico.configuracaoFinanceiroContabil ? objetoTotal.totalFinanceiroContabil : objetoTotal.totalTransferenciaContabil }}</td>
        <td class="text-right limitar">{{ objetoTotal.numeroReduzido }}</td>
        <td class="limitar">{{ objetoTotal.contaContabil }}</td>
        <td class="limitar">{{ objetoTotal.debitoCredito == -1 ? bibDialogo.credito : bibDialogo.debito }}</td>
        <td class="limitar">{{ objetoTotal.tipoLancamentoContabil }}</td>
        <td class="limitar">{{ objetoTotal.lancamentoDireto == 'S' ? bibDialogo.sim : bibDialogo.aguardandoNfe }}</td>
        <td>
          <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModal(objetoTotal)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirObjetoTotal(i)"></btnAdicional>
        </td>
      </tr>
    </tbody>
  </table>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirObjeto()"></botao>
<auditoria *ngIf="objeto.id" [idUsuarioInclusao]="objeto.idUsuarioInclusao" [dataHoraInclusao]="objeto.dataHoraInclusao" [idUsuarioAlteracao]="objeto.idUsuarioAlteracao" [dataHoraAlteracao]="objeto.dataHoraAlteracao"></auditoria>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
