import { Component } from '@angular/core';
import { Comissao } from 'src/app/modelo/comissao';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { MovimentacaoFrmComponent } from '../movimentacao/frm/estrutura/movimentacaoFrm.component';
import filtros from './filtro';

@Component({
  templateUrl: './comissaoFaturamento.component.html',
  styleUrls: ['./comissaoFaturamento.component.css'],
})
export class ComissaoFaturamentoComponent extends PaginaComponent {
  public apresentaDadosComissao: boolean = true;
  public comissoes: Comissao[] = [];
  public componente = null;
  public dataVencimento: Date = new Date();
  public filtros = filtros;
  public valorTotalPagar: number = 0;
  public totalComissoes: any[] = [];
  public visualizaTotalizadores: boolean = false;

  ngOnInit(): void {
    let idFinanceiro: Identificacao = this.utilSessao.getIdentificacao('idFinanceiro')?.conteudo;
    this.apresentaDadosComissao = this.util.coalesce(this.utilSessao.getIdentificacao('apresentaDadosComissao')?.conteudo, true);
    if (idFinanceiro != null) {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ID_COMISSAO_FINANCEIRO', idFinanceiro));
      this.listar(criterios);
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.comissao).subscribe((res) => {
      this.comissoes = this.plainToClass(Comissao, res) as any;
      this.valorTotalPagar = 0;
      this.totalizarComissoes();
    });
  }

  excluirComissao(id: number, nome: string): void {
    super.excluir<Comissao>(id, this.comissoes, nome);
  }

  visualizar(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = MovimentacaoFrmComponent;
  }

  setSelecionado(comissao: Comissao, marcado: string): void {
    comissao.selecionado = marcado;
    this.setValorLote(comissao);
    this.calcularTotal();
  }

  calcularTotal(): void {
    let valor: number = 0;
    this.comissoes.forEach((comissao) => {
      if (comissao.selecionado == 'S') {
        valor += comissao.valorDisponivel > 0 ? comissao.valorDisponivel : comissao.valorRestante;
        this.setValorLote(comissao);
      }
    });
    this.valorTotalPagar = valor;
  }

  setValorLote(comissao: Comissao): void {
    if (comissao.selecionado == 'S') {
      comissao.valorLote = comissao.valorDisponivel != 0 ? comissao.valorDisponivel : comissao.valorRestante;
    } else {
      comissao.valorLote = 0;
    }
  }

  gerarComissaoLote(): void {
    if (this.ehValido()) {
      let lote: string = new Date().getTime().toString() + this.utilSessao.getUsuario().id;
      let comissaoSelecionadas: Comissao[] = this.comissoes.filter((comissao) => comissao.selecionado == 'S');
      comissaoSelecionadas.forEach((comissao) => {
        comissao.dataVencimentoPagamentoComissao = this.dataVencimento;
        comissao.dataSolicitacaoPagamento = new Date();
        comissao.valorPago += comissao.valorDisponivel != 0 ? comissao.valorDisponivel : comissao.valorRestante;
      });
      if (comissaoSelecionadas.length > 0) {
        comissaoSelecionadas.forEach((comissao) => (comissao.lote = lote));
        comissaoSelecionadas.sort((a, b) => (a['valorLote'] == b['valorLote'] ? 0 : a['valorLote'] < b['valorLote'] ? 1 : -1));
        this.persistirComissao(comissaoSelecionadas);
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecioneComissao));
      }
    }
  }

  persistirComissao(comissoesSelecionadas: Comissao[]): void {
    super.persistir(new Transporte(comissoesSelecionadas), this.bibServico.comissao, null).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.comissoes.forEach((comissao) => (comissao.selecionado = 'N'));
        this.valorTotalPagar = 0;
      }
      this.comunicacaoService.listar(new Transporte([new Criterio('IDS', this.comissoes.map((comissao) => comissao.id).toString())], 0, 0, true, this.utilSessao.numeroRegistro), this.bibServico.comissao).subscribe((res) => {
        this.comissoes = this.plainToClass(Comissao, res) as any;
        this.utilSessao.getResultados().forEach((resultado) => {
          if (resultado.classe == this.bibClasse.aprovacao || (resultado.mensagem && resultado.mensagem.indexOf('Aprovação pendente') > -1)) {
            this.comissoes.forEach((comissao) => {
              comissoesSelecionadas.forEach((comissaoSelecionada) => {
                if (comissaoSelecionada.id == comissao.id && comissao.valorRestante != 0) {
                  comissao.selecionado = 'S';
                }
              });
            });
            this.calcularTotal();
          }
        });
      });
    });
  }

  ehValido(): boolean {
    if (this.dataVencimento) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  selecionarComissaoDisponivel(marcado: string): void {
    if (marcado == 'S') {
      this.comissoes.forEach((comissao) => {
        if (comissao.valorDisponivel != 0) {
          comissao.selecionado = 'S';
        }
      });
    } else {
      this.comissoes.forEach((comissao) => {
        comissao.selecionado = 'N';
      });
    }
    this.calcularTotal();
  }

  totalizarComissoes(): void {
    this.totalComissoes = [];
    let valorTotalComissao: number = 0;
    let valorTotalLiberado: number = 0;
    let valorTotalNaoLiberado: number = 0;
    let valorTotalPago: number = 0;
    let valorTotalAguardando: number = 0;
    let valorTotalAPagar: number = 0;
    for (let comissao of this.comissoes) {
      valorTotalComissao += comissao.valor;
      valorTotalLiberado += comissao.valorLiberado;
      valorTotalNaoLiberado += comissao.valor - comissao.valorLiberado;
      valorTotalPago += comissao.valorPago;
      valorTotalAguardando += comissao.valorDisponivel;
      valorTotalAPagar += comissao.valorRestante;
    }

    this.totalComissoes.push({ valorTotalComissao: valorTotalComissao, valorTotalLiberado: valorTotalLiberado, valorTotalNaoLiberado: valorTotalNaoLiberado, valorTotalPago: valorTotalPago, valorTotalAguardando: valorTotalAguardando, valorTotalAPagar: valorTotalAPagar });
  }
}
