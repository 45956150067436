<div [ngClass]="registro == 0 && segundaVia == false ? 'quebra-pagina': ''">
  <div class="relatorio-titulo">{{ bibDialogo.notaPromissoria.toUpperCase() }}</div>
  <div class="pl-0 fonte-10pt">
    <div *ngIf="movimentacao.status != 'S'" class="tarja-nao-finalizado">{{ bibDialogo.naoFinalizado }}</div>
    <div class="pt-2">{{bibDialogo.numero + ': ' + movimentacao.numero}}{{totalRegistro > 1 ? ' - ' + (registro + 1) + '/' + totalRegistro : ''}}</div>
    <div>{{bibDialogo.dataVencimento + ': ' + movimentacao.vencimentoDia + ' de ' + this.util.escreverMes(movimentacao.vencimentoMes) +  ' de ' + movimentacao.vencimentoAno}}</div>
    <div>{{bibDialogo.valor + ': '}}<b>{{'R$ ' + (movimentacao.valorParcela  | monetario)}}</b></div>
    <div *ngIf="movimentacao.statusNfe == '100'" >{{bibDialogo.notaFiscal + ': ' + movimentacao.numero }}</div>
    <div *ngIf="movimentacao.statusNfe != '100'" class="pt-2 text-justify">{{'No dia ' + movimentacao.vencimentoDia + ' no mês de ' + this.util.escreverMes(movimentacao.vencimentoMes) + ' de ' + movimentacao.vencimentoAno + ' pagarei por esta única via de nota promissória a ' + movimentacao.loja + ' inscrita no CNPJ sob o número ' + movimentacao.lojaCnpj + ', ou a sua ordem, a quantidade de R$ ' + (movimentacao.valorParcela  | monetario) +  ' (' + this.util.escreverValor(movimentacao.valorParcela) + ') em moeda corrente deste país.'}} </div>
    <div *ngIf="movimentacao.statusNfe == '100'" class="pt-2 text-justify">{{'No dia ' + movimentacao.vencimentoDia + ' no mês de ' + this.util.escreverMes(movimentacao.vencimentoMes) + ' de ' + movimentacao.vencimentoAno + ' pagarei por esta única via de nota promissória a ' + movimentacao.loja + ' inscrita no CNPJ sob o número ' + movimentacao.lojaCnpj + ', ou a sua ordem, a quantidade de R$ ' + (movimentacao.valorParcela  | monetario) +  ' (' + this.util.escreverValor(movimentacao.valorParcela) + ') em moeda corrente deste país, referente à seguinte nota fiscal de produto(s) vendidos(s) e já entregues a mim.'}}</div>     
    
    <table *ngIf="produtos">
      <tr class="border-bottom border-dark">
        <th class="r">{{ bibDialogo.produto }}</th>
        <th class="r-2 text-right">{{ bibDialogo.quantidade }}</th>
        <th class="r-2">{{ bibDialogo.unidadeAbreviacao }}</th>
        <th class="r-2 text-right">{{ bibDialogo.valor }}</th>
      </tr>
      <tbody>
        <tr class="sem-borda" *ngFor="let movimentacaoProduto of movimentacao.movimentacaoProdutos">
          <td>{{ movimentacaoProduto.produto }}</td>
          <td class="text-right">{{ movimentacaoProduto.quantidade | monetario }}</td>     
          <td>{{ movimentacaoProduto.unidade }}</td>
          <td class="text-right">{{ movimentacaoProduto.valorTotalBruto | monetario }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3"></td>
          <td class="border-top border-dark text-right"><b>{{ movimentacao.valorTotalBrutoProduto | monetario }}</b></td>
        </tr>                              
        <tr *ngIf="movimentacao.descontoTotal && movimentacao.descontoTotal > 0">
          <td class="text-right" colspan="3">{{ bibDialogo.desconto }}</td>
          <td class="text-right">{{ movimentacao.descontoTotal | monetario }}</td>
        </tr>                    
        <tr *ngIf="movimentacao.encargos && movimentacao.encargos > 0">
          <td class="text-right" colspan="3">{{ bibDialogo.encargos }}</td>
          <td class="text-right">{{ movimentacao.encargos | monetario }}</td>
        </tr>                  
        <tr *ngIf="movimentacao.descontoTotal || movimentacao.encargos">
          <td colspan="3"></td>
          <td class="border-top border-dark text-right"><b>{{ movimentacao.valorTotal | monetario }}</b>{{totalRegistro > 1 ? ' EM ' + totalRegistro + 'x' : ''}}</td>
        </tr>
      </tfoot>                  
    </table>

    <div class="pt-2">{{bibDialogo.praca + ' de ' + bibDialogo.pagamento.toLowerCase() + ': ' + movimentacao.lojaCidade + ' - ' + movimentacao.lojaEstado}}</div>
    <div class="pt-2">{{movimentacao.negociacaoDia + ' de ' + this.util.escreverMes(movimentacao.negociacaoMes) +  ' de ' + movimentacao.negociacaoAno + '.'}}</div>
    <div class="row">
      <div class="col">
        <div class="assinatura">{{movimentacao.parceiroNumero +  ' - ' + movimentacao.parceiro}}</div>
        <div class="text-center">{{(movimentacao.parceiroCpfCnpj.length > 14 ? bibDialogo.cnpj : bibDialogo.cpf) + ': ' + movimentacao.parceiroCpfCnpj}}</div>
        <div *ngIf="movimentacao.parceiroIdentidade" class="text-center">{{'Identidade: ' + movimentacao.parceiroIdentidade}}</div>
      </div>
      <div *ngIf="avalista" class="col">
        <div class="assinatura">{{movimentacao.parceiroAvalista}}</div>
        <div class="text-center">AVALISTA</div>
      </div>
    </div>
    <div class="text-center">{{bibDialogo.endereco + ': ' + movimentacao.parceiroEndereco}}</div>
    <div class="linha-pontilhada"></div>
  </div>
</div>