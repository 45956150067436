import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ProdutoFrmComponent } from 'src/app/pagina/produto/frm/produtoFrm.component';
import { ImagemComponent } from 'src/app/essencial/imagem/imagem.component';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { ProdutoValorComponent } from 'src/app/pagina/produtoValor/produtoValor.component';
import { IcmsFrmComponent } from 'src/app/pagina/imposto/icms/icmsFrm.component';

@Component({
  templateUrl: './r1014.component.html',
  styleUrls: ['./r1014.component.css'],
})
export class R1014Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public produtos: any[];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public componente: any;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.produtos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Produto').lista;
      this.ordenarAgrupar(this.produtos);
      this.ehImprimirRelatorio();
    });
  }

  irProduto(idProduto: number): void {
    const MENU_PRODUTO = 7;
    this.componente = this.abrirModalRelatorio(ProdutoFrmComponent, [new Identificacao('idProduto', idProduto)], MENU_PRODUTO);
  }

  mostrarFoto(produto: any): void {
    produto.mostraFoto = !produto.mostraFoto;
    if (produto.mostraFoto && (produto.produtoFotos == null || produto.produtoFotos.length == 0)) {
      this.listarEspecifico(new Criterio('ID_PRODUTO', produto.id), 205).subscribe((res) => {
        produto.produtoFotos = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }

  abrirModalImagem(imagemBase64: string): void {
    this.utilSessao.setIdentificacao(new Identificacao('imagemBase64', imagemBase64));
    this.componente = ImagemComponent;
  }

  irProdutoValor(idProduto: number, idLoja: number): void {
    const MENU_PRODUTO_VALOR = 73;
    this.componente = this.abrirModalRelatorio(ProdutoValorComponent, [new Identificacao('idProduto', idProduto), new Identificacao('idLoja', idLoja)], MENU_PRODUTO_VALOR);
  }

  irIcms(idProdutoIcms: number): void {
    const SAIDA: number = -1;
    this.componente = this.abrirModalRelatorio(IcmsFrmComponent, [new Identificacao('tipo', SAIDA), new Identificacao('classe', null), new Identificacao('idProdutoIcms', idProdutoIcms)], null);
  }
}
